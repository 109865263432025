import withAPIAction from 'utils/withAPIAction'
import {
  createDataAction,
  getDataListAction,
  updateDataAction,
} from './actions'
import { GetDataListReq, ConfigERCListDetailReq } from './types'
import { verifyReq } from 'utils/verify'

export const onGetConfigurationERC = (token: string, data: GetDataListReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetDataListReq>({
    method: 'POST',
    path: 'GetConfigurationERC',
    data,
    token: `Bearer ${token}`,
  })(getDataListAction)
}

export const onCreateConfigurationERC = (
  token: string,
  data: ConfigERCListDetailReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<ConfigERCListDetailReq>({
    method: 'POST',
    path: 'CreateConfigurationERC',
    data,
    token: `Bearer ${token}`,
  })(createDataAction)
}

export const onUpdateConfigurationERC = (
  token: string,
  data: ConfigERCListDetailReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<ConfigERCListDetailReq>({
    method: 'POST',
    path: 'UpdateConfigurationERC',
    data,
    token: `Bearer ${token}`,
  })(updateDataAction)
}
