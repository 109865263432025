import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { Row, Col } from 'antd'
import RootState from 'typings/RootState'
import { DropdownList, DropdownPopupMenu } from 'Components/Shared/Form'
import { CloseIcon } from 'Components/Shared/Icon'
import PrimaryButton from 'Components/Shared/Button/PrimaryButton'
import { InputText } from 'Components/Shared/Input'
import { DataItem } from 'features/admin/qcType/types'
import { ProjectListItem } from 'features/project/project.types'
import { selectDataList } from 'features/admin/project/selector'

type Props = ReduxProps &
  DispatchProps & {
    projectList: any
    item: any
    onSave?: (item: any) => void
    onClose: () => void
    isUpdateMode?: boolean
  }

const SettingConfigDetail: React.ComponentType<Props> = props => {
  const { item, isUpdateMode = false, projectList } = props
  const [dataItem, setdataItem] = useState({} as any)
  const [projectFilter, setProjectFilter] = useState([] as ProjectListItem[])
  const [searchTxt, setSearchTxt] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    setdataItem(item)
  }, [item])

  const status = [
    {
      name: 'Active',
      value: 'A',
    },
    {
      name: 'InActive',
      value: 'N',
    },
  ]

  const statusFlagPost = [
    {
      name: 'True',
      value: '1',
    },
    {
      name: 'Fasle',
      value: '0',
    },
  ]

  const statusQCType = [
    {
      name: 'QC1',
      value: 'QC1',
    },
    {
      name: 'QC2',
      value: 'QC2',
    },
  ]

  const ercTypeOfWork = [
    {
      name: 'ERC001',
      value: 'ERC001',
    },
    {
      name: 'ERC002',
      value: 'ERC002',
    },
    {
      name: 'ERC003',
      value: 'ERC003',
    },
    {
      name: 'ERC004',
      value: 'ERC004',
    },
  ]

  useEffect(() => {
    const project: ProjectListItem[] = Object.values(projectList)
    setProjectFilter(project)
  }, [projectList])

  useEffect(() => {
    filterProject()
  }, [searchTxt])

  const getProjectItem = () => {
    let menu = [] as any
    menu.push({
      name: `โครงการทั้งหมด`,
      value: '',
    })

    projectFilter.forEach((item: ProjectListItem, index: number) => {
      menu.push({
        name: renderName(item),
        value: item.ProjectCode,
      })
    })
    return menu
  }

  const hasText = (data: string, searchTxt: string) => {
    if (data && searchTxt) {
      return data.toLocaleLowerCase().includes(searchTxt.toLocaleLowerCase())
    }
    return false
  }

  const filterProject = () => {
    let list: ProjectListItem[] = Object.values(projectList)
    if (searchTxt !== '') {
      list = list.filter((item: ProjectListItem) => {
        return (
          hasText(item.ProjectCode, searchTxt) ||
          hasText(item.ProjectNameTH, searchTxt) ||
          hasText(item.ProjectNameEN, searchTxt)
        )
      })
    }
    setProjectFilter(list)
  }

  const renderName = (item: ProjectListItem) => {
    return `${item.ProjectCode}  - ${item.ProjectNameTH}`
  }

  const getActiveProject = (projectCode: string) => {
    const project: ProjectListItem[] = Object.values(projectList)
    const projectActive = project.find(item => item.ProjectCode === projectCode)
    return projectActive ? renderName(projectActive) : ''
  }

  const onSave = () => {
    if (props.onSave) {
      props.onSave(dataItem)
    }
  }
  return (
    <MainPage>
      <Header>
        <CloseIcon color="#8b99a7" size={24} onClick={props.onClose} />
      </Header>
      <Row>
        <Col span={24}>
          <Title>ตั้งค่า Config ERC</Title>
        </Col>
        <Col span={24}>
          <BodyArea>
            <DropdownList
              label="โครงการ"
              placeholder={
                dataItem.ProjectCod
                  ? getActiveProject(dataItem.ProjectCode)
                  : 'กรุณาเลือกโครงการ'
              }
              value={dataItem.ProjectCode}
              menu={getProjectItem()}
              onChange={(val: string) => {
                console.log(val)
                dataItem.ProjectCode = val
                setdataItem({ ...dataItem })
              }}
              hasSearch={true}
              onSearch={setSearchTxt}
            />
          </BodyArea>
        </Col>

        <Col span={24}>
          <BodyArea>
            <InputText
              prefix="Activity Name"
              value={dataItem.ActivityName}
              onChange={(val: string) => {
                dataItem.ActivityName = val
                setdataItem({ ...dataItem })
              }}
              placeholder="ระบุ Activity Name"
            />
          </BodyArea>
        </Col>

        <Col span={24}>
          <BodyArea>
            <InputText
              prefix="Template Code"
              value={dataItem.TemplateCode}
              onChange={(val: string) => {
                dataItem.TemplateCode = val
                setdataItem({ ...dataItem })
              }}
              placeholder="ระบุ TemplateCode"
            />
          </BodyArea>
        </Col>

        <Col span={24}>
          <BodyArea>
            <InputText
              prefix="ProcurementTaskID"
              value={dataItem.ProcurementTaskID}
              onChange={(val: string) => {
                dataItem.ProcurementTaskID = val
                setdataItem({ ...dataItem })
              }}
              placeholder="ระบุ ProcurementTaskID"
            />
          </BodyArea>
        </Col>

        <Col span={24}>
          <BodyArea>
            <DropdownList
              label="Flag Post"
              value={dataItem.FlagPost == true ? '1' : '0'}
              menu={statusFlagPost}
              onChange={(val: string) => {
                if (val == '1') {
                  dataItem.FlagPost = true
                } else {
                  dataItem.FlagPost = false
                }
                setdataItem({ ...dataItem })
              }}
            />
          </BodyArea>
        </Col>

        <Col span={24}>
          <BodyArea>
            <DropdownList
              label="ประเภทงานติดตั้ง"
              value={dataItem.ERCWorkType}
              menu={ercTypeOfWork}
              onChange={(val: string) => {
                dataItem.ERCWorkType = val
                setdataItem({ ...dataItem })
              }}
            />
          </BodyArea>
        </Col>

        <Col span={24}>
          <BodyArea>
            <DropdownList
              label="หมวด QC"
              value={dataItem.QCType}
              menu={statusQCType}
              onChange={(val: string) => {
                dataItem.QCType = val
                setdataItem({ ...dataItem })
              }}
            />

            {/* 
            <InputText
              prefix="QCType"
              value={dataItem.QCType}
              onChange={(val: string) => {
                dataItem.QCType = val
                setdataItem({ ...dataItem })
              }}
              placeholder="ระบุ QCType"
            /> */}
          </BodyArea>
        </Col>

        {isUpdateMode == true && (
          <Col span={24}>
            <BodyArea>
              <DropdownList
                label="สถานะ"
                value={dataItem.Active}
                menu={status}
                onChange={(val: string) => {
                  dataItem.Active = val
                  setdataItem({ ...dataItem })
                }}
              />
            </BodyArea>
          </Col>
        )}
      </Row>
      <Row type="flex" justify="end">
        <SaveArea>
          <PrimaryButton onClick={onSave} text="บันทึก" />
        </SaveArea>
      </Row>
    </MainPage>
  )
}

const MainPage = styled.div`
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  overflow-x: scroll;
`

const Header = styled.div`
  text-align: right;
  height: 40px;

  svg {
    cursor: pointer;
  }
`

const Title = styled.div`
  color: black;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
  margin-bottom: 14px;
`

const Text = styled.div`
  color: black;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
`

const SaveArea = styled.div`
  margin-top: 32px;
  float: right;
  width: 160px;
`

const BodyArea = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  span {
    font-size: 20px;
  }
  .ant-dropdown-trigger {
    margin-bottom: 12px;
  }
  .input-title {
    margin-bottom: 12px;
  }
`

const mapStateToProps = (state: RootState) => {
  return {
    // projectList: selectDataList(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(SettingConfigDetail)
