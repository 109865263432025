import RootState from 'typings/RootState'
import { ConfigList } from './types'
import { Event } from '../../types/event'

export const selectDataList = (state: RootState): ConfigList =>
  state.adminSettingConfigERC.DataList

export const selectEvent = (state: RootState): Event =>
  state.adminSettingConfigERC.event

export const selectSorting = (state: RootState) =>
  state.adminSettingConfigERC.sorting
