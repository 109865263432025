import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import { GetApiResponse, PaginationType } from '../../types/common'
import { CommonResp, ConfigERCListDetailReq, GetDataListResp } from './types'
import { History } from 'history'
import { ConfigListDetailReq } from '../setting-config/types'

export const reset = createStandardAction('admin/settingConfig/RESET_ACTION')<
  void
>()

export const sortingTask = createStandardAction('admin/setting/SORTING')<{
  name: string
  sort?: string
}>()

export const getDataListAction = createAsyncAction(
  'admin/setting-erc/GET_CONFIG_ERC_LIST_REQUEST',
  'admin/setting-erc/GET_CONFIG_ERC_LIST_SUCCESS',
  'admin/setting-erc/GET_CONFIG_ERC_LIST_FAILURE'
)<PaginationType, GetDataListResp, Error>()

export const getReasonListAction = createAsyncAction(
  'admin/setting-erc/GET_CONFIG_ERC_LIST_REASON_REQUEST',
  'admin/setting-erc/GET_CONFIG_ERC_LIST_REASON_SUCCESS',
  'admin/setting-erc/GET_CONFIG_ERC_LIST_REASON_FAILURE'
)<PaginationType, GetDataListResp, Error>()

export const createDataAction = createAsyncAction(
  'admin/setting-erc/CREATE_CONFIG_DETAIL_REQUEST',
  'admin/setting-erc/CREATE_CONFIG_DETAIL_SUCCESS',
  'admin/setting-erc/CREATE_CONFIG_DETAIL_FAILURE'
)<ConfigERCListDetailReq, CommonResp, any>()

export const updateDataAction = createAsyncAction(
  'admin/setting-erc/UPDATE_CONFIG_DETAIL_REQUEST',
  'admin/setting-erc/UPDATE_CONFIG_DETAIL_SUCCESS',
  'admin/setting-erc/UPDATE_CONFIG_DETAIL_FAILURE'
)<ConfigERCListDetailReq, CommonResp, any>()

export const resetEvent = createStandardAction(
  'admin/qcType/RESET_EVENT_ACTION'
)<void>()
