import React from 'react'
import { Col } from 'antd'
import styled from 'styled-components'
import { TableRow } from 'Components/Shared/Table'
import { mapUnitStatus, unitStatusIsPass } from 'utils/status-map'
import {
  StatusTag,
  ReportLink as Link,
} from 'Components/AdminQC/Report/Components'
import { numberFormat } from 'utils/format'
import { EditIcon, TrashIcon } from 'Components/Shared/Icon'
import { OutlineButton } from 'Components/Shared/Button'
import { toMomentDateTime, toDateText } from 'utils/datetime'
import { ConfigERCListDetail } from 'features/admin/setting-config-erc/types'

type Props = {
  onClick?: (evt: any, item: any) => void
  item: ConfigERCListDetail
  selected?: boolean
}

const RowItem: React.FC<Props> = props => {
  const { item, selected } = props
  const onClick = (evt: any) => {
    if (props.onClick) {
      props.onClick(evt, item)
    }
  }

  const text = item.Active === 'A' ? 'Publish' : 'Not publish'
  const bg = item.Active === 'A' ? '#5bc79e' : '#efc122'
  const flagPostbg = item.FlagPost == true ? '#5bc79e' : '#dc2626'
  const flagPost = item.FlagPost == true ? 'True' : 'False'
  const renderData = (val: any, span: number = 2) => {
    return <Col span={span}>{val || '-'}</Col>
  }

  return (
    <RowModel>
      <TableRow
        minHeight={70}
        spaceBetweenRow={8}
        paddingLeft={24}
        onClick={onClick}
        selected={selected}
      >
        <Col span={1}>
          <Bold>{item.ID}</Bold>
        </Col>
        <Col span={2}>
          <TxtReg>{item.ProjectCode}</TxtReg>
        </Col>

        <Col span={3}>
          <TxtReg>{item.ActivityName}</TxtReg>
        </Col>

        <Col span={3}>
          <TxtReg>{item.TemplateCode}</TxtReg>
        </Col>
        <Col span={4}>
          <TxtReg>{item.ProcurementTaskID}</TxtReg>
        </Col>
        <Col span={2}>
          <TxtReg>{item.ERCWorkType != null ? item.ERCWorkType : ''}</TxtReg>
        </Col>
        <Col span={2}>
          <TxtReg>{item.QCType != null ? item.QCType : ''}</TxtReg>
        </Col>
        <Col span={2}>
          <Status style={{ backgroundColor: flagPostbg }}>{flagPost}</Status>
        </Col>
        <Col span={2}>
          <TxtReg>
            {item.CreateBy != null ? item.CreateBy.split('@')[0] : ''}
          </TxtReg>
        </Col>
        <Col span={3}>
          <Status style={{ backgroundColor: bg }}>{text}</Status>
        </Col>
        {/* <Col span={2}>
          <Icon onClick={() => {}}>
            <EditIcon size={20} color="#8b99a7" />
          </Icon>
        </Col> */}
      </TableRow>
    </RowModel>
  )
}

const RowModel = styled.div`
  .ant-row {
    cursor: auto;
  }
`

const Score: any = styled.div`
  font-size: 20px;
  font-family: 'DB Helvethaica X 75 Bd';
  text-decoration: underline;
  cursor: pointer;
`

const PassScore = styled(Score)`
  color: #63cea6;
`

const FailScore = styled(Score)`
  color: #d0494c;
`

const Bold: any = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
  font-size: 20px;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding-right: 5px;
`

const TxtReg = styled(Bold)`
  font-family: 'DB Helvethaica X 55 Regular';
`

const Icon = styled.div`
  float: left;
  margin-right: 20px;
  margin-top: 5px;
  cursor: pointer;
`
const Status = styled.div`
  height: 20px;
  border-radius: 10px;
  color: white;
  text-align: center !important;
  font-size: 16px;
  padding: 5px 15px;
  font-size: 16px;
  align-items: center;
  display: inline-flex;
`

export default RowItem
