import React from 'react'
import SideMenu from '../Shared/SideMenu'

const AdminMenu = (props: any) => {
  const { selected, children } = props
  return (
    <SideMenu selected={selected} list={menu}>
      {children}
    </SideMenu>
  )
}

const menu: any = [
  {
    name: 'Admin',
    list: [
      {
        name: 'Project list',
        key: 'project',
        link: `/project`,
      },
      {
        name: 'Tribe / Squad',
        key: 'tribe',
        link: `/tribe`,
      },
      {
        name: 'Users',
        key: 'user',
        link: `/user`,
      },
      {
        name: 'SOW',
        key: 'position',
        link: `/position`,
      },
      {
        name: 'หมวดงาน QC',
        key: 'qctype',
        link: `/qc-type`,
      },
      {
        name: 'ตั้งค่าอีเมล',
        key: 'email-setting',
        link: `/email-setting`,
      },
      {
        name: 'Sync Primavera Data',
        key: 'sync-primavera-task',
        link: `/sync-primavera-task`,
      },
      {
        name: 'Update Activity Progress',
        key: 'update-activity-progress',
        link: `/update-activity-progress`,
      },
      {
        name: 'Auto Email',
        key: 'auto-email',
        link: `/auto-email`,
      },
      {
        name: 'ตั้งค่า Config',
        key: 'setting-config',
        link: `/setting-config`,
      },
      {
        name: 'ตั้งค่า Config ERC',
        key: 'setting-config',
        link: `/setting-config-erc`,
      },
    ],
  },
  {
    name: 'Master checklist',
    list: [
      {
        name: 'Master Checklist Maincon',
        key: 'master-checklist',
        link: `/master-checklist`,
      },
      {
        name: 'Master ตรวจเอกสาร',
        key: 'master-document',
        link: `/master-document`,
      },
      // {
      //   name: 'Mapping Template Code',
      //   key: 'mapping-template-code',
      //   link: '/mapping-template-code',
      // },
    ],
  },
]

export default AdminMenu
