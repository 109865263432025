import React, { useEffect, useState } from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import ImagePicker from 'Components/Shared/ImagePicker'
import DocumentPicker from 'Components/Shared/DocumentPicker'
import moment from 'moment'
import Calendar from 'Components/Shared/Calendar'
import ProgressUpdate from 'Components/Shared/ProgressUpdate'
import { RemarkBox } from 'Components/Activity'
import {
  Activity,
  CurrentActivity,
  VendorCodeItem,
} from 'features/cmpj/activity/types'
import { StateOfTask, StatusOfTask } from 'features/types/status'
import { HintList } from 'Components/Shared'
import { CompanyVandorItem } from 'features/admin/user/types'

import { useDispatch } from 'react-redux'
import InputSearch from 'Components/Shared/Input/InputSearch'

type Props = {
  data: CurrentActivity
  original: Activity
  onChange: (val: CurrentActivity) => void
  readOnly?: boolean
  onImageOpen(list?: any[], readOnly?: boolean): void
  onDocumentOpen(list?: any[], readOnly?: boolean): void
  onThumbnailOpen(index: number, list?: any[], readOnly?: boolean): void
  vendorList: VendorCodeItem[]
  readOnlyQc?: boolean
}

const TaskProgress: React.FunctionComponent<Props> = ({
  data,
  original,
  onChange,
  readOnly = false,
  onImageOpen,
  onDocumentOpen,
  onThumbnailOpen,
  vendorList,
  readOnlyQc = false,
}) => {
  const dispatch = useDispatch()
  const [searchVendor, setSearchVendor] = useState(
    original?.Vendor?.VendorName ?? ''
  )
  const [filterVendorList, setFilterVendorList] = useState(
    vendorList ?? ([] as VendorCodeItem[])
  )

  useEffect(() => {
    if (vendorList.length > 0) {
      setFilterVendorList(vendorList)
    }
  }, [vendorList])

  useEffect(() => {
    if (searchVendor == '') {
      setFilterVendorList(vendorList)
    }

    onFindUser()
  }, [searchVendor])

  const onFindUser = () => {
    setFilterVendorList(
      vendorList.filter(
        v =>
          v.VendorCode.search(searchVendor) != -1 ||
          v.VendorName.search(searchVendor) != -1
      )
    )
  }

  const onChangeProgress = (val: number) => {
    data.RequestProgress = val
    onChange(data)
    updatedDate(val)
  }

  const onChangeVendor = (val: string) => {
    data.VendorCode = val
    onChange(data)
    setFilterVendorList(vendorList)
  }

  const updatedDate = (val: number) => {
    if (val === 100) {
      onActualFinishDate(moment())
    } else {
      onActualFinishDate('')
    }
  }

  const getDate = (val: string) => {
    return val !== '' ? moment(val, 'YYYYMMDD') : undefined
  }

  const getTextDate = (val: any) => {
    return val ? val.format('YYYYMMDD') : ''
  }

  const onExpectDate = (val: any) => {
    data.ExpectFinishDate = getTextDate(val)
    onChange(data)
  }

  const onActualStartDate = (val: any) => {
    data.ActualStartDate = getTextDate(val)
    onChange(data)
  }

  const onActualFinishDate = (val: any) => {
    data.ActualFinishDate = getTextDate(val)
    onChange(data)
  }

  const onRemark = (val: string) => {
    data.Remark = val
    onChange(data)
  }

  const onImage = (val: any) => {
    data.Images = val
    onChange(data)
  }

  const onDocument = (val: any) => {
    data.Documents = val
    onChange(data)
  }

  const renderUserDataFormat = (item: CompanyVandorItem) => {
    let content = `${item.VendorCode} - ${item.VendorName}`
    return content
  }

  const renderSearchContent = (list: CompanyVandorItem[]) => {
    const empty = (
      <ContentBody>
        <Txt>ไม่มีข้อมูล</Txt>
      </ContentBody>
    )

    const content =
      list && list.length > 0 ? (
        <SearchContentArea>
          {list.map((item, index) => {
            const textdesc = renderUserDataFormat(item)
            return (
              <SearchItem
                key={index}
                onClick={() => {
                  if (
                    readOnlyQc == false &&
                    readOnly == false &&
                    data.RequestProgress == 100
                  ) {
                    setSearchVendor(item.VendorName)
                    onChangeVendor(item.VendorCode)
                  }
                }}
              >
                {textdesc}
              </SearchItem>
            )
          })}
        </SearchContentArea>
      ) : (
        empty
      )

    return content
  }
  const { ConstructionTypeName } = original

  const progress = (
    <Row>
      <Col span={14}>
        {' '}
        <ProgressUpdate
          lastProgress={data.CurrentProgress}
          disabled={readOnly}
          originalProgress={data.CurrentProgress}
          progress={data.RequestProgress}
          onChangeProgress={onChangeProgress}
        />{' '}
      </Col>

      {data.RequestProgress == 100 && original.FlagERC == true && (
        <Col span={10}>
          <BoxVendor>
            <InputSearch
              placeholder={'เลือกผู้รับเหมา'}
              value={searchVendor}
              onChange={(val: string) => {
                if (
                  readOnlyQc == false &&
                  readOnly == false &&
                  data.RequestProgress == 100
                ) {
                  setSearchVendor(val)
                }
              }}
              showSuggess={true}
              searchContent={renderSearchContent(filterVendorList)}
              readOnly={readOnly}
            />
          </BoxVendor>
        </Col>
      )}

      {/* <Col span={18}>
          {' '}
          <ProgressUpdate
            lastProgress={data.CurrentProgress}
            disabled={readOnly}
            originalProgress={data.CurrentProgress}
            progress={data.RequestProgress}
            onChangeProgress={onChangeProgress}
          />{' '}
        </Col> */}
    </Row>
  )

  const expectDate = getDate(data.ExpectFinishDate)
  const actualStateDate = getDate(data.ActualStartDate)
  const actualFinishDate = getDate(data.ActualFinishDate)

  const calendar = (
    <Row gutter={16}>
      <Col span={12}>
        <Calendar
          disabled={original.Progress > 0 || readOnly}
          onSelectedDate={onActualStartDate}
          label="วันที่เริ่มจริง"
          value={actualStateDate}
          maxDate={moment()
            .startOf('day')
            .add(1, 'day')}
        />
      </Col>
      {data.RequestProgress >= 100 ? (
        <Col span={12}>
          <Calendar
            disabled={true}
            label="วันแล้วเสร็จจริง"
            value={actualFinishDate}
            minDate={moment().startOf('day')}
          />
        </Col>
      ) : (
        <Col span={12}>
          <Calendar
            disabled={readOnly}
            label="วันที่คาดแล้วเสร็จ"
            onSelectedDate={onExpectDate}
            value={expectDate}
            minDate={moment().startOf('day')}
          />
        </Col>
      )}
    </Row>
  )

  const fileInfo = (
    <FileInfo>
      <Row type="flex" justify="space-between">
        <Col md={12}>
          <ImagePicker
            Images={data.Images}
            onChange={onImage}
            onImageOpen={() =>
              onImageOpen(
                data.Images,
                // readOnly
                data.RequestProgress == 100 && original.TaskStatus === 'APPV'
                  ? false
                  : readOnly
              )
            }
            onThumbnailOpen={index =>
              // Change to Open All List Instant Only one PopUp
              // onThumbnailOpen(index, data.Images, readOnly)
              onImageOpen(
                data.Images,
                data.RequestProgress == 100 && original.TaskStatus === 'APPV'
                  ? false
                  : readOnly
              )
            }
            disabled={
              data.RequestProgress == 100 && original.TaskStatus === 'APPV'
                ? false
                : readOnly
            }
            showMax={3}
            numberOfImage={50}
            isMultiple={true}
          />
        </Col>
        <Col md={12}>
          <DocumentPicker
            Documents={data.Documents}
            onChange={onDocument}
            onDocumentOpen={() =>
              onDocumentOpen(
                data.Documents,
                data.RequestProgress == 100 && original.TaskStatus === 'APPV'
                  ? false
                  : readOnly
              )
            }
            disabled={
              data.RequestProgress == 100 && original.TaskStatus === 'APPV'
                ? false
                : readOnly
            }
            showMax={3}
            numberOfFile={50}
            isMultiple={true}
          />
        </Col>
      </Row>
      <Space />
      <RemarkBox onChange={onRemark} value={data.Remark} readOnly={readOnly} />
      <Space />
    </FileInfo>
  )

  const isReject =
    original.State === StateOfTask.CM &&
    original.TaskStatus === StatusOfTask.REJT
  const rejectReason = (
    <>
      <TextItem>เหตุผลที่ไม่อนุมัติ</TextItem>
      <TextDes>{original.RejectReason || '-'}</TextDes>
      <Space />
    </>
  )

  return (
    <MainView>
      <TextHeader>จัดการความคืบหน้า</TextHeader>
      {!readOnly && (
        <HintList
          hintList={[
            'กรุณาระบุวันที่เริ่มจริง',
            'กรุณาระบุวันที่คาดแล้วเสร็จ',
            'กรุณาระบุความคืบหน้า (%)',
          ]}
        />
      )}

      <BoxItem>
        <TextItem>วันทำงาน</TextItem>
        {calendar}
        <Space />
        {progress}
        {ConstructionTypeName === 'Road' && (
          <WarningLabel>
            <p>กรุณาตรวจสอบ % ของงานที่ส่งขออนุมัติ</p>
            <p>กรณี งานชั้นทาง (งาน 100% หารจำนวนจุดทดสอบ)</p>
            <p>งานเทคอนกรีต (งาน 100% หาร จำนวน BAY)</p>
          </WarningLabel>
        )}
        <Space />
        {isReject && rejectReason}
        {fileInfo}
      </BoxItem>
    </MainView>
  )
}

const SearchContentArea = styled.div`
  max-height: 192px;
  overflow: scroll;
`
const ContentBody = styled.div`
  width: 100%;
  text-align: center;
  padding: 70px 0;
  height: 192px;
`

const SearchItem = styled.div`
  padding: 8px 24px;
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
  &:hover {
    background-color: #e6f7ff;
    cursor: pointer;
  }
`

const WarningLabel = styled.div`
  color: red;
  font-size: 16px;
  font-family: 'DB Helvethaica X 55 Regular';
  line-height: 12px;
`
const MainView = styled.div`
  margin-top: 40px;
`
const TextHeader = styled.div`
  font-size: 28px;
  font-family: 'DB Helvethaica X 75 Bd';

  span {
    font-family: 'DB Helvethaica X 55 Regular';
    padding-left: 4px;
  }
`

const Txt = styled.div`
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
`

const TextItem = styled.div`
  font-size: 20px;
  font-family: 'DB Helvethaica X 75 Bd';
  margin-bottom: 10px;

  div {
    font-family: 'DB Helvethaica X 55 Regular';
  }
`

const TextDes = styled.div`
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
`

const Space = styled.div`
  margin-top: 24px;
`

const FileInfo = styled.div``

const BoxItem = styled.div`
  background-color: #fff;
  margin-top: 12px;
  border-radius: 5px;
  border: solid 1px #f2f2f2;
  padding: 24px;
  line-height: normal;
`

const BoxVendor = styled.div`
  padding-top: 23px;
  padding-left: 20px;
`

export default TaskProgress
