import { put, take, takeEvery, select, all } from 'redux-saga/effects'
import { APIPath } from 'services/APIPath'
import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { getUserProfile } from 'features/sensitive/sensitive.selector'
import * as uploadAction from '../../uploadfile/actions'

import {
  userEmailSelector,
  serviceTokenSelector,
} from '../../sensitive/sensitive.selector'

import { projectCodeWithSuffixSelector } from '../../project/project.selector'
import { sendActivityToPJ, updateAttachmentActivity } from './api'
import { UpdateAttachmentReq } from './types'
import { uploadFileReq } from '../../uploadfile/api'
import { setWatermark } from '../../uploadfile/func'
import projectHelper from 'utils/projectHelper'

function* resetSubmitError() {
  yield put(actions.clearSubmitError())
}

function* uploadImgList(userEmail: string, token: string, list: any[]) {
  const sizePerReq = 5
  let result: any = []
  const profile = yield select(getUserProfile)
  const { FirstName = '', LastName = '' } = profile
  const username = `${FirstName} ${LastName} (CM)`

  const num = Math.ceil(list.length / sizePerReq)
  for (let i = 0; i < num; i++) {
    const start = i * sizePerReq
    const temp = list.slice(start, start + sizePerReq)
    let formData = new FormData()
    formData.set('UserEmail', userEmail)

    for (let index = 0; index < temp.length; index++) {
      const respFile = yield setWatermark(username, temp[index])
      formData.append('Files', respFile.file)
    }

    yield put(uploadFileReq(formData, token))
    const resp = yield take(uploadAction.uploadFileAsyncReq.success)
    const resultList = resp.payload.ResponseData
    result = result.concat(resultList)
  }

  return result
}

function* uploadFileList(userEmail: string, token: string, list: any[]) {
  const sizePerReq = 5
  let result: any = []
  const num = Math.ceil(list.length / sizePerReq)

  for (let i = 0; i < num; i++) {
    const start = i * sizePerReq
    const temp = list.slice(start, start + sizePerReq)
    let formData = new FormData()
    formData.set('UserEmail', userEmail)
    temp.forEach((item: any) => formData.append('Files', item.file))

    yield put(uploadFileReq(formData, token))
    const resp = yield take(uploadAction.uploadFileAsyncReq.success)
    const resultList = resp.payload.ResponseData
    result = result.concat(resultList)
  }

  return result
}

function* sendReqActivity(action: any) {
  const { req, Token } = action.payload

  try {
    if (req.Images.length > 0) {
      req.Images = yield uploadImgList(req.UserEmail, Token, req.Images)
    }
    if (req.Documents.length > 0) {
      req.Documents = yield uploadFileList(req.UserEmail, Token, req.Documents)
    }
    console.log('req')
    console.log(req)

    yield put(sendActivityToPJ(req, Token))
  } catch (e) {}
}

function* uploadImgListAfterTaskDone(
  userEmail: string,
  token: string,
  list: any[]
) {
  const sizePerReq = 5
  let result: any = []
  const profile = yield select(getUserProfile)
  const codeModule = projectHelper.getModule() || ''
  const RoleName = codeModule
  const { FirstName = '', LastName = '' } = profile
  const username = `${FirstName} ${LastName} (${RoleName})`

  const num = Math.ceil(list.length / sizePerReq)
  for (let i = 0; i < num; i++) {
    const start = i * sizePerReq
    const temp = list.slice(start, start + sizePerReq)
    let formData = new FormData()
    formData.set('UserEmail', userEmail)

    for (let index = 0; index < temp.length; index++) {
      const respFile = yield setWatermark(username, temp[index])
      formData.append('Files', respFile.file)
    }

    yield put(uploadFileReq(formData, token))
    const resp = yield take(uploadAction.uploadFileAsyncReq.success)
    const resultList = resp.payload.ResponseData
    resultList.map((img: any) => {
      img.FileType = 'I'
    })
    result = result.concat(resultList)
  }
  return result
}

function* uploadFileListAfterTaskDone(
  userEmail: string,
  token: string,
  list: any[]
) {
  const sizePerReq = 5
  let result: any = []
  const num = Math.ceil(list.length / sizePerReq)

  for (let i = 0; i < num; i++) {
    const start = i * sizePerReq
    const temp = list.slice(start, start + sizePerReq)
    let formData = new FormData()
    formData.set('UserEmail', userEmail)
    temp.forEach((item: any) => formData.append('Files', item.file))

    yield put(uploadFileReq(formData, token))
    const resp = yield take(uploadAction.uploadFileAsyncReq.success)
    const resultList = resp.payload.ResponseData
    resultList.map((file: any) => {
      file.FileType = 'D'
    })
    result = result.concat(resultList)
  }
  return result
}

function* sendReqAttachment(
  action: ReturnType<typeof actions.submitAttachmentListReq>
) {
  const { req, Token } = action.payload
  let uploadImages: any = []
  let uploadDocuments: any = []
  try {
    if (req.ImageList.length > 0) {
      uploadImages = yield uploadImgListAfterTaskDone(
        req.UserEmail,
        Token,
        req.ImageList
      )
    }
    if (req.DocumentsList.length > 0) {
      uploadDocuments = yield uploadFileListAfterTaskDone(
        req.UserEmail,
        Token,
        req.DocumentsList
      )
    }

    const params: UpdateAttachmentReq = {
      UserEmail: req.UserEmail,
      RequestID: req.RequestID,
      Files: [...uploadDocuments, ...uploadImages],
      RemoveFiles: req.RemoveFiles,
    }

    yield put(updateAttachmentActivity(params, Token))
  } catch (e) {
    yield put(actions.sendAttachmentList.failure(e))
  }
}

function* allSaga() {
  yield all([
    yield takeEvery(getType(actions.submitActivityToPJ), sendReqActivity),
    yield takeEvery(getType(actions.resetSubmitErrorAction), resetSubmitError),
    yield takeEvery(
      getType(actions.submitAttachmentListReq),
      sendReqAttachment
    ),
  ])
}

export default allSaga
