import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import { Layout } from 'antd'
import TopNav from 'Components/Shared/TopNav'
import TokenExpired from 'Components/Pages/TokenExpired'

import AdminPrimavera from 'Components/Admin/Primavera'
import AdminProject from 'Components/Admin/Project'
import AdminPosition from 'Components/Admin/Position'
import AdminQcType from 'Components/Admin/QcType'

import WelcomePage from 'Components/Admin/Welcome/Administrator'
import UserList from 'Components/Admin/User/List'
import UserDetail from 'Components/Admin/User/Detail'

import MasterDocumentList from 'Components/Admin/MasterDocument/List'
import MasterCheckList from 'Components/Admin/MasterChecklist/List'
import MainconChecklistDetail from '../Components/Inspection/Activity/MainconChecklistDetail'
import Tribe from 'Components/Admin/Tribe'
import EmailSetting from 'Components/Admin/EmailSetting'
import SyncPrimaveraTask from 'Components/Admin/SyncPrimaveraTask'
import UpdateActivityPregress from 'Components/Admin/UpdateActivityProgress'
import MappingTemplateCode from 'Components/Admin/MappingTemplateCode'
import AutoEmail from 'Components/Admin/AutoEmail'
import SettingConfig from 'Components/Admin/SettingConfig'
import SettingConfigERC from './Admin/SettingConfigERC'

type Props = {}

const AdminApp: React.FunctionComponent<Props> = () => {
  return (
    <BrowserRouter>
      <Layout className="layout" hasSider={false}>
        <TopNav title="ADMINISTRATOR" displayProject={false} />
        <Route exact path="/" component={WelcomePage} />

        <Switch>
          <Route exact path="/dashboard" component={WelcomePage} />
          <Route exact path="/primavera" component={AdminPrimavera} />
          <Route exact path="/project" component={AdminProject} />
          <Route exact path="/position" component={AdminPosition} />
          <Route exact path="/qc-type" component={AdminQcType} />

          <Route exact path="/token-expired" component={TokenExpired} />
          <Route exact path="/tribe" component={Tribe} />
          <Route exact path="/user" component={UserList} />
          <Route exact path="/user/create" component={UserDetail} />

          <Route exact path="/master-checklist" component={MasterCheckList} />
          <Route
            exact
            path="/master-checklist/:id/edit"
            component={MainconChecklistDetail}
          />
          <Route
            exact
            path="/master-checklist/create"
            component={MainconChecklistDetail}
          />

          <Route exact path="/master-document" component={MasterDocumentList} />
          <Route exact path="/email-setting" component={EmailSetting} />
          <Route
            exact
            path="/sync-primavera-task"
            component={SyncPrimaveraTask}
          />
          <Route
            exact
            path="/update-activity-progress"
            component={UpdateActivityPregress}
          />
          <Route
            exact
            path="/mapping-template-code"
            component={MappingTemplateCode}
          />
          <Route exact path="/auto-email" component={AutoEmail} />
          <Route exact path="/setting-config" component={SettingConfig} />

          <Route
            exact
            path="/setting-config-erc"
            component={SettingConfigERC}
          />
        </Switch>
      </Layout>
    </BrowserRouter>
  )
}

export default AdminApp
